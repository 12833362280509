@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
    font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #7a7f9d;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #21295c;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #7a7f9daf;
    /* transition: background-color 2s ease; */
}


/* Background Color: #21295c */
/* Hover & Date Selecting color: 191f45 */
/* Text color: #ffe3a3 */

/* styling react-datepicker */
.react-datepicker {
    font-family: "Inter", sans-serif;
    background-color: #21295c;
    color: #ffe3a3 !important;
    border: none;
    margin-right: 7px !important;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #fff;
    background-color: #21295c;
    background-clip: padding-box;
    border: 0.5px solid #ffe3a36b;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input:focus {
    color: #fff;
    background-color: #ffe3a30e;
    border-color: #ffe3a3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(238, 255, 0, 0.25);
}

.react-datepicker-wrapper{
  
    padding-right: "5px" !important;
    margin-right: "5px" !important;
}